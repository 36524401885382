import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemPanel, AccordionItemState } from "react-accessible-accordion";

import ButtonSolid from "../components/Button/ButtonSolid";
import HeroStacked from "../components/Hero/HeroStacked";
import CallToAction from "../components/Repeating/CTA";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";

const Page = ({ data }) => {
   const url = typeof window !== "undefined" ? window.location.pathname : "";

   const coveringTheBasics = [
      {
         question: "What is Estate Planning?",
         answer:
            "We help you create your personal legacy. Estate planning is about much more than just the transfer of your money and property. That’s why we’ve developed a process to help you transfer your most important personal assets to your loved ones – your intellectual, spiritual and human assets – who you are and what’s important to you.",
      },
      {
         question: "Why Hire an Estate Planning Attorney?",
         answer:
            "Before you engage an estate planning lawyer to help you plan for the well‐being of your money, your family, and your life, there are questions you need to ask. You need to ensure that the estate plan you put in place will really work when it’s needed and won’t end up just a pile of worthless paper.",
      },
      {
         question: "How Do I Begin Estate Planning?",
         answer:
            "Your first step is to schedule a Family Wealth Planning Session with us. Please make sure you’ve read up on what we’ll do together in that meeting before you come in, so that you come in prepared to be educated, informed and empowered to  make decisions. Call us at 619-987-3532, or schedule a 15 minute intro call to get started on your  Family Wealth Planning Session.",
      },
      {
         question: "Before I Hire an Estate Planning Lawyer, What Should I Know? ",
         answer:
            "Many estate planning attorneys take a “one-size fits all” approach with their clients; they do this so that they can work with as many people as possible in a short period of time. In addition to leaving the client with a totally cold and impersonal experience, this way of planning can lead to unintended results in the long run. Conscious Legal is different.",
      },
      {
         question: "Why Should I Contact Conscious Legal?",
         answer:
            "Here’s the most important part for you to know about this: you will be making informed, educated, and empowered decisions for the people you love, not just shopping around based on price. And the best part is that as part of this process, you will actually choose your own fee based on your budget and the planning options that are most important to you. There truly is no one size fits all choice. We have created a process, though, to educate you quickly and effectively so that you can make empowered, informed choices about what you want for the people you love, so that you aren’t simply choosing the cheapest option because you don’t have any other basis for making your decisions.",
      },
   ];

   const whereToStart = [
      {
         question: "What if I Do/Don’t Have a Plan?",
         answer:
            "If you do not have a plan, we will cover the plan that the state of California has for you. If you do have a plan, we will review your current plan with you. (We call this an estate plan review and check-up). If you are not happy with your current plan (or the plan that California has for you), you will tell us what you would want to change, and we will look at the most effective, efficient and affordable way to achieve your goals.",
      },
      {
         question: "What About My Existing Estate Planning Documents?",
         answer:
            "If you have existing estate planning documents, please make sure that we have your existing documents in our office at least a week before your meeting so that we can review them prior to meeting with you.",
      },
      {
         question: "What if My Attorney Did Not Update my Estate Planning Documents?",
         answer:
            "When lawyers do not take the time to listen and consider their clients’ personal circumstances, their estate planning documents do not end up reflecting what they really want to happen. And usually, there is no review or updating, so the plan quickly becomes outdated and does not work when it is needed most. Even if you end up paying less for one of these “churn and burn” plans, things end up much more expensive in the long run. Plus, you leave your family or loved ones at risk of having to deal with an expensive and unnecessary court process during a time of tremendous grief.",
      },
      {
         question: "What if My Children are Adults?",
         answer:
            "If your children are adults, we help you weave them into your estate plan with more care and forethought than most other lawyers ever consider.",
      },
      {
         question: "What If I Have Family Members I Would Never Want To Raise My Kids?",
         answer:
            "We plan for your life, not just your death, and ensure that if you have minor children, they are never raised by anyone you wouldn’t want or taken into the care of strangers.",
      },
   ];

   const feesPricing = [
      {
         question: "How Much Does an Estate Plan Cost?",
         answer:
            "As you can imagine, we are asked this question quite frequently. And, it’s a good question that we want to answer for you as clearly as we can, given there is not a “one-size fits all solution” for every client, and I would run quickly away from any lawyer who can tell you exactly what your plan would cost via email or over the phone, without a comprehensive personal process. This is exactly why most estate plans fail. People shop around based on price (because they don’t know any other way to shop for an estate plan), and end up with a traditional plan, which is a set of documents that do not actually work when they are needed.",
      },
      {
         question: "How Do You Price Estate Planning?",
         answer:
            "We’ve priced our estate planning at the intersection of affordability and effectiveness. That’s why we start all of our plans with a Family Wealth Planning Session, which is designed to get you more financially organized than you have ever been before, and ensure that no matter what happens, none of your hard-earned assets will be lost to the State Department of Unclaimed Property.",
      },
      {
         question: "What is Your Price Range for Estate Planning?",
         answer:
            "Our plans range from $2,000 for a basic plan to $8,000 for a very complex plan, and everywhere in between. During our session, you will pick the Level of Planning, and the fee, that is right for you based on your needs and goals.",
      },
      {
         question: "How Much is a Family Wealth Planning Session?",
         answer:
            "The Planning Session Fee is $750; however, we agree to waive that fee if you agree to do some pre-meeting homework ahead of time to make our meeting as productive as possible, and secure your appointment with a credit card.",
      },
      {
         question: "Can I Get a Complimentary Family Wealth Planning Session?",
         answer:
            "Yes! The session is $750 if you come in unprepared and uneducated. If you commit to doing some pre-meeting homework and to show ready to learn and take charge of your family's future, we will waive the session fee.",
      },
      {
         question: "Can I Waive the Fee for the Planning Session?",
         answer:
            "Those above fees are applicable if you come in fully uneducated, but can be waived if you commit to doing a little pre-meeting homework ahead of time.",
      },
      {
         question: "Can I Revise My Plan at No Charge?",
         answer:
            "All of our plans include complimentary changes for up to 90 days after you sign your documents. From there, you have the option to join our Client VIP Membership Program, which allows for significant discounts on future amendments as well as many other benefits. We see the signing of your plan as only the beginning of our relationship - our goal is to serve as your Personal Family Lawyer for life, so that you and your family always have someone to turn to, and your estate plan keeps up with your life, your assets, and the law.",
      },
      {
         question: "How Do You Calculate Fees?",
         answer:
            "All of our fees are fixed fees agreed to in advance, so there are never any surprises. At our initial meeting, you will select the level of planning and fee that works best for you.",
      },
      {
         question: "Why Do You Secure the Complimentary Session with a Credit Card?",
         answer:
            "If you have received a complimentary session and have secured it with a credit card, nothing will be charged on your card so long as you keep your appointment and complete your paperwork ahead of time. We understand this policy of securing your appointment with a credit card may seem unique. So is our law firm. We go above and beyond to those who are looking for a long-term relationship with a trusted advisor. We expend most of our energy providing an exceedingly high degree of service to our existing clients, and limit the number of new clients we see each week. As a result, we must secure your appointment time so that if something comes up and you can’t attend your appointment, we will have enough advance notice to open the spot to a waiting client.",
      },
   ];

   const ourServices = [
      {
         question: "What Does Your Estate Planning Provide?",
         answer:
            "We don’t just prepare a set of legal documents for you and send you on your way. We make sure your assets are owned in the right way, your kids’ well-being is properly planned for, everyone you’ve named in your plan knows what to do if anything happens to you, and make sure that none of your assets become part of the $58 billion of unclaimed assets across the United States.",
      },
      {
         question: "What Does a Family Wealth Planning Session Cover?",
         answer:
            "During our meeting together, we will guide you to look at what would happen for your loved ones and to your assets if something were to happen to you. Then, we’ll review what you want to happen and help you create a roadmap to implement your plan as easily as possible. Clients who have procrastinated for years routinely tell us how easy and seamless we make this process.",
      },
      {
         question: "Why Should I Schedule the Family Wealth Planning Session?",
         answer:
            "Even if you never do any planning with us, the Family Wealth Planning Session alone is a hugely valuable process, because you will create a full inventory of your assets and ensure your loved ones knows what you have. Then, when we meet in person, we will review everything you own, and discuss everyone you love, and you will understand exactly what would happen in the event of your death or incapacity.",
      },
      {
         question: "Do You Offer Continuous Services?",
         answer:
            "Conscious Legal is right for you if you want to know that your plan will actually work when you and your loved ones need it most. If all you want is a set of form legal documents, there are plenty of online resources you can use to create them, or you can go to a traditional estate planning lawyer. But if what you want is a truly personal and concierge experience, and to have a trusted advisor who will be there for you and your family in all stages of life, then we might be the right fit for you.",
      },
      {
         question: "Do You Advise On College Savings, Insurance, and Retirement?",
         answer:
            "We help you get your legal and financial house in order by helping you make smart choices about things like buying insurance, saving for college, and retirement planning so you never spend more than you have to or get taken advantage of by unscrupulous sales people.",
      },
      {
         question: "How Often Will You Review My Plan?",
         answer:
            "We review your plan at least every 3 years. We foster a lifetime, ongoing relationship with our clients. Throughout your life, things change. You change; your assets change; your family situation changes; the law will definitely change. When planning with us, your plan will keep up with those changes.",
      },
   ];

   const workingWithUs = [
      {
         question: "How Do I Schedule My Session?",
         answer: "Get started by giving us a call at 619-987-3532, or book a 15 minute intro call using the link at the bottom of this page.",
      },
      {
         question: "What Happens After I Schedule My Appointment?",
         answer:
            "After scheduling your appointment, you will receive your Estate Planning Worksheet and other important information that you will want to review prior to your personal Family Wealth Planning Session. You will return your Estate Planning Worksheet to our office at least 3 days prior to your Session or we will need to reschedule your Session. Feel free to call the office at any time if you need assistance completing your Worksheet, or have any questions.",
      },
      {
         question: "How Should I Prepare for Our Meeting?",
         answer:
            "If you’d like to do a little preparation ahead of time, and secure your appointment time with a credit card (though nothing is charged on your card so long as you keep your appointment and turn in your homework in advance), you can schedule by calling our office and have the fee waived. That saves me time, and saves you money.",
      },
      {
         question: "What Should I Expect During Our Meeting?",
         answer: (
            <div>
               <p>
                  On the day of your meeting, please expect to spend up to 2 hours with your attorney. The initial meeting with your attorney has two
                  purposes:
               </p>
               <ul className="list-disc ml-4 mb-6">
                  <li className="mb-2">
                     To identify whether there is a good fit between you and our attorney who will become your Personal Family Lawyer.
                  </li>
                  <li>To educate you about the law and what would happen if you died with your current (or no) plan in place.</li>
               </ul>
               <p>
                  By looking at what would happen under your current plan, you can identify what you would want to happen differently and then
                  together with your Personal Family Lawyer’s guidance make the decisions that are necessary (including choosing your own fee) to give
                  you the peace of mind of knowing your loved ones would stay out of court, chaos and conflict, if something happens to you.
               </p>
            </div>
         ),
      },
      {
         question: "How Long Is a Family Wealth Planning Session?",
         answer:
            "We block 2 hours on our calendar so we can focus entirely on you. We will send you a package of information to complete before our time together, which will help you begin the process of getting your legal and financial life in order.",
      },
      {
         question: "When Do We Create A Plan?",
         answer:
            "Assuming you and your attorney determine there is a good fit between our firm and you AND that you identify specific ways you would want things to happen that are different than the current status, you and your Personal Family Lawyer can design your plan immediately.",
      },
      {
         question: "What Happens After My Plan is Designed?",
         answer: (
            <div>
               <p>
                  Once you and your Personal Family Lawyer have completed the design of your plan, you will work with our team to review your assets
                  for the purpose of ensuring that everything you own will be titled for maximum protection, and then schedule a meeting for you to
                  sign your planning documents approximately 4-6 weeks later.
               </p>
               <p>
                  We have a process of reviewing your documents with you that makes your plan so easy to understand that you will know for certain
                  that we understood exactly the way you want things to happen, and that your loved ones will be in excellent hands with our firm.
               </p>
            </div>
         ),
      },
      {
         question: "What Happens During Our Final Meeting?",
         answer:
            "At our final meeting during the planning stage of our relationship, we verify that all of your asset transfers are moving forward on track whether we are taking the lead or you are taking the lead with our guidance. We review your asset spreadsheet with you and ensure all of your questions are answered. Of course, if they aren’t or they become cloudy later on, we are always available for you at any time in the future. And, there’s no charge for that. Then, we'll focus on capturing your most valuable, and often lost when someone dies, intangible assets - your unique stories, insights, values, and experiences. We'll create a Legacy Interview with you to be kept with the digital copies of your plan, so that future generations will appreciate not just the assets you leave behind, but the stories that come with them.",
      },
   ];

   return (
      <Layout>
         <SearchEngineOptimization
            title="FAQ | Estate Planning Law Firm | Conscious Legal"
            description="Learn answers to the most common questions people ask our attorneys. Conscious Legal is a top estate planning law firm based in San Diego, CA"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroStacked
            image={data.heroDesktop.childImageSharp.gatsbyImageData}
            imageMaxHeight="max-h-[480px]"
            backgroundFixed={false}
            heading="FAQs"
            subtext=""
            subtextSize=""
            subtextClassName=""
            textMaxWidth="max-w-3xl"
         >
            <p className="font-heading text-mobile-7xl md:text-7xl font-bold gradient-text uppercase mb-6 md:mb-2">You have a legacy to leave.</p>
            <p className="font-heading italic text-mobile-3xl md:text-3xl text-white font-medium mb-7 md:mb-10">
               We’ll help you protect it for your family and business.
            </p>
            <ButtonSolid as="button" modal="modal-contact" text="Get a Free Consultation" />
         </HeroStacked>

         <section className="pt-20 md:pt-28">
            <div className="container">
               <ScrollWrapper className="grid lg:grid-cols-12 gap-y-16 gap-x-10">
                  <ScrollContent className="lg:col-start-1 lg:col-span-8">
                     <div id="section-1" className="mb-20 md:mb-24">
                        <header className="mb-10 md:mb-12">
                           <h2>Covering The Basics</h2>
                        </header>

                        <Accordion allowZeroExpanded={true} className="border-t border-solid border-black border-opacity-30">
                           {coveringTheBasics.map((faq, i) => {
                              return (
                                 <div key={i}>
                                    <AccordionItem className="border-b border-solid border-black border-opacity-30 pt-6 pb-5" uuid={i}>
                                       <AccordionItemButton className="flex items-center focus:outline-none">
                                          <AccordionItemState>
                                             {(state) => {
                                                const icon = state.expanded ? "fa-minus" : "fa-plus";
                                                return <i className={`fal ${icon} text-xl text-black mr-6`}></i>;
                                             }}
                                          </AccordionItemState>

                                          <p className="font-heading text-lg font-bold text-black mb-0">{faq.question}</p>
                                       </AccordionItemButton>
                                       <AccordionItemPanel className="pt-4 pl-10 animate-fadeIn">
                                          <p className="mb-0">{faq.answer}</p>
                                       </AccordionItemPanel>
                                    </AccordionItem>
                                 </div>
                              );
                           })}
                        </Accordion>
                     </div>

                     <div id="section-2" className="mb-20 md:mb-24">
                        <header className="mb-10 md:mb-12">
                           <h2>Where to Start</h2>
                        </header>

                        <Accordion allowZeroExpanded={true} className="border-t border-solid border-black border-opacity-30">
                           {whereToStart.map((faq, i) => {
                              return (
                                 <div key={i}>
                                    <AccordionItem className="border-b border-solid border-black border-opacity-30 pt-6 pb-5" uuid={i}>
                                       <AccordionItemButton className="flex items-center focus:outline-none">
                                          <AccordionItemState>
                                             {(state) => {
                                                const icon = state.expanded ? "fa-minus" : "fa-plus";
                                                return <i className={`fal ${icon} text-xl text-black mr-6`}></i>;
                                             }}
                                          </AccordionItemState>

                                          <p className="font-heading text-lg font-bold text-black mb-0">{faq.question}</p>
                                       </AccordionItemButton>
                                       <AccordionItemPanel className="pt-4 pl-10 animate-fadeIn">
                                          <p className="mb-0">{faq.answer}</p>
                                       </AccordionItemPanel>
                                    </AccordionItem>
                                 </div>
                              );
                           })}
                        </Accordion>
                     </div>

                     <div id="section-3" className="mb-20 md:mb-24">
                        <header className="mb-10 md:mb-12">
                           <h2>Fees & Pricing Structure</h2>
                        </header>

                        <Accordion allowZeroExpanded={true} className="border-t border-solid border-black border-opacity-30">
                           {feesPricing.map((faq, i) => {
                              return (
                                 <div key={i}>
                                    <AccordionItem className="border-b border-solid border-black border-opacity-30 pt-6 pb-5" uuid={i}>
                                       <AccordionItemButton className="flex items-center focus:outline-none">
                                          <AccordionItemState>
                                             {(state) => {
                                                const icon = state.expanded ? "fa-minus" : "fa-plus";
                                                return <i className={`fal ${icon} text-xl text-black mr-6`}></i>;
                                             }}
                                          </AccordionItemState>

                                          <p className="font-heading text-lg font-bold text-black mb-0">{faq.question}</p>
                                       </AccordionItemButton>
                                       <AccordionItemPanel className="pt-4 pl-10 animate-fadeIn">
                                          <p className="mb-0">{faq.answer}</p>
                                       </AccordionItemPanel>
                                    </AccordionItem>
                                 </div>
                              );
                           })}
                        </Accordion>
                     </div>

                     <div id="section-4" className="mb-20 md:mb-24">
                        <header className="mb-10 md:mb-12">
                           <h2>Our Services</h2>
                        </header>

                        <Accordion allowZeroExpanded={true} className="border-t border-solid border-black border-opacity-30">
                           {ourServices.map((faq, i) => {
                              return (
                                 <div key={i}>
                                    <AccordionItem className="border-b border-solid border-black border-opacity-30 pt-6 pb-5" uuid={i}>
                                       <AccordionItemButton className="flex items-center focus:outline-none">
                                          <AccordionItemState>
                                             {(state) => {
                                                const icon = state.expanded ? "fa-minus" : "fa-plus";
                                                return <i className={`fal ${icon} text-xl text-black mr-6`}></i>;
                                             }}
                                          </AccordionItemState>

                                          <p className="font-heading text-lg font-bold text-black mb-0">{faq.question}</p>
                                       </AccordionItemButton>
                                       <AccordionItemPanel className="pt-4 pl-10 animate-fadeIn">
                                          <p className="mb-0">{faq.answer}</p>
                                       </AccordionItemPanel>
                                    </AccordionItem>
                                 </div>
                              );
                           })}
                        </Accordion>
                     </div>

                     <div id="section-5">
                        <header className="mb-10 md:mb-12">
                           <h2>Working with Us</h2>
                        </header>

                        <Accordion allowZeroExpanded={true} className="border-t border-solid border-black border-opacity-30">
                           {workingWithUs.map((faq, i) => {
                              return (
                                 <div key={i}>
                                    <AccordionItem className="border-b border-solid border-black border-opacity-30 pt-6 pb-5" uuid={i}>
                                       <AccordionItemButton className="flex items-center focus:outline-none">
                                          <AccordionItemState>
                                             {(state) => {
                                                const icon = state.expanded ? "fa-minus" : "fa-plus";
                                                return <i className={`fal ${icon} text-xl text-black mr-6`}></i>;
                                             }}
                                          </AccordionItemState>

                                          <p className="font-heading text-lg font-bold text-black mb-0">{faq.question}</p>
                                       </AccordionItemButton>
                                       <AccordionItemPanel className="pt-4 pl-10 animate-fadeIn">
                                          <p className="mb-0">{faq.answer}</p>
                                       </AccordionItemPanel>
                                    </AccordionItem>
                                 </div>
                              );
                           })}
                        </Accordion>
                     </div>
                  </ScrollContent>

                  <ScrollNavigation className="hidden lg:block lg:col-end-13 lg:col-span-3">
                     <li>
                        <AnchorLink to={url + "#section-1"} title="The Basics" stripHash />
                     </li>
                     <li>
                        <AnchorLink to={url + "#section-2"} title="Where to Start" stripHash />
                     </li>
                     <li>
                        <AnchorLink to={url + "#section-3"} title="Fees & Pricing" stripHash />
                     </li>
                     <li>
                        <AnchorLink to={url + "#section-4"} title="Our Services" stripHash />
                     </li>
                     <li>
                        <AnchorLink to={url + "#section-5"} title="Working with Us" stripHash />
                     </li>
                  </ScrollNavigation>
               </ScrollWrapper>
            </div>
         </section>

         <CallToAction headingLevel="h2" />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FAQ_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/FAQ_TW.jpg" }) {
         publicURL
      }
      heroDesktop: file(relativePath: { eq: "faq/faqs-hero-desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
   }
`;
export default Page;
